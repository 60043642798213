import * as React from 'react';

export default function SitemarkIcon() {
  return (
    <img
      height={50}
      style={{margin: "1.5em"}}
      src='download.png'>
    </img>
  );
}
